<template>
  <page-data-section :title="titleText"
                     :description="descriptionText"
                     class="hotline-dashboard">
    <div class="hotline-dashboard__content-wrapper">
      <p v-if="interpreterCount"
         class="hotline-dashboard__availability">{{ availableInterAmountText }}</p>
      <sk-select v-if="hasSeveralDepartments"
                 :items-list="departmentsList"
                 :description="departmentsText"
                 :default-value="departmentsText"
                 :preselected-value="department"
                 class="hotline-dashboard__departments"
                 @csvaluechanged="catchDepartment" />
      <sk-note v-if="hasSeveralDepartments"
               :text="hotlineDepartmentInfo"
               class="hotline-dashboard__department-info" />
      <div class="hotline-dashboard__instructions">
        <div v-for="(step, index) in stepsList"
             :key="index"
             class="hotline-dashboard__instruction">
          <p class="hotline-dashboard__instruction-step">{{ getStepText(index) }}</p>
          <div class="hotline-dashboard__instruction-content">
            <p class="hotline-dashboard__instruction-action">{{ step.action }}</p>
            <a v-if="step.call"
               :href="`tel:${step.text}`"
               class="sk-link hotline-dashboard__instruction-text js-copy-to-clipboard">{{ step.text }}</a>
            <b v-else
               class="hotline-dashboard__instruction-text">{{ step.text }}</b>
          </div>
        </div>
      </div>
      <p class="hotline-dashboard__additional-info">
        <span class="hotline-dashboard__description-text">{{ $gettext('For more info,') }}</span>
        <button class="sk-link sk-link--gray-bg hotline-dashboard__description-btn"
                @click="showHotlineInfoModal">{{ clickHereText }}</button>
        <router-link v-if="hasSeveralDepartments"
                     :to="$makeRoute({name: 'BuyerHotlineCodes'})"
                     class="hotline-dashboard__print-btn">{{ $gettext('Print out your pin nr(s)') }}</router-link>
      </p>
    </div>
  </page-data-section>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';

  export default {
    components: {
      'page-data-section': PageDataSection
    },
    props: {
      langPair: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        department: ''
      };
    },
    computed: {
      ...mapState('TTHotlineStore', {
        pinCodes: (state) => state.pinCodes || [],
        phones: (state) => state.phones || [],
        interpreterCount: (state) => state.interpreterCount || ''
      }),
      titleText() { return this.$gettext('<span>NEW</span> Arabic Hotline'); },
      descriptionText() { return this.$gettext('Get immediate access to Arabic interpreters'); },
      departmentsText() { return this.$gettext('Select a department to get the right pin'); },
      clickHereText() { return this.$gettext('click here'); },
      hasSeveralDepartments() { return this.pinCodes.length > 1; },
      hotlineDepartmentInfo() {
        const template = this.$gettext('If your department is not in this list, <a class="sk-link" href="mailto:%{support}?subject=Arabic Hotline">contact us to get your department code</a>.');
        return this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
      },
      availableInterAmountText() {
        const template = this.$gettext('%{n} Available interpreters now');
        return this.$gettextInterpolate(template, {n: this.interpreterCount});
      },
      arabicTranslationPhone() { return this.langPair.number || ''; },
      formattedPhone() {
        const phone = String(this.arabicTranslationPhone);
        if (phone) {
          return !phone.indexOf(47) ? phone.substring(2) : `+${phone}`;
        }
        return '';
      },
      pinCode() {
        const selectedDepartment = this.pinCodes.find((item) => {
          return !item.id || item.id == this.department;
        }) || {};
        return selectedDepartment.hotlinePinCode || '';
      },
      stepsList() {
        return [
          {action: this.$gettext('Call'), text: this.formattedPhone, call: true},
          {action: this.$gettext('Enter pin'), text: this.pinCode},
          {action: this.$gettext('Get'), text: this.$gettext('connected')}
        ];
      },
      departmentsList() {
        return this.pinCodes.map((item) => {
          return {
            id: item.id,
            name: this.genDepartmentText(item.name, item.hotlinePinCode)
          };
        });
      }
    },
    methods: {
      ...mapActions('TTHotlineStore', ['updateHotlineDepartment', 'getAvailableInterpreters']),
      catchDepartment(value) {
        const form = new FormData();

        form.append('department[default]', true);

        this.department = value;
        this.updateHotlineDepartment({form, departmentId: value}).catch((error) => error);
      },
      preselectDepartment() {
        if (this.pinCodes.length) {
          if (this.pinCodes.length === 1 && this.pinCodes[0].id) {
            return this.catchDepartment(this.pinCodes[0].id);
          }
          this.pinCodes.forEach((item) => {
            if (item.default) {
              this.catchDepartment(item.id);
            }
          });
        }
      },
      genDepartmentText(department, pin) {
        const template = this.$gettext('%{department} - Pin %{pin}');
        return this.$gettextInterpolate(template, {department, pin});
      },
      getStepText(index) {
        return this.$gettextInterpolate(this.$gettext('Step %{n}'), {n: index + 1});
      },
      showHotlineInfoModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'hotline-info-modal'
        });
      }
    },
    beforeMount() {
      this.preselectDepartment();
    }
  };
</script>

<style>
  .hotline-dashboard .page-data-section__heading {
    margin-bottom: 10px;
  }

  .hotline-dashboard .page-data-section__title {
    font-weight: normal;
    font-size: 18px;
  }

  .hotline-dashboard .page-data-section__title span {
    color: #f93;
    text-decoration: underline;
  }
</style>

<style scoped>
  .hotline-dashboard {
    font-size: 12px;
  }

  .hotline-dashboard__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }

  .hotline-dashboard__availability {
    margin-bottom: 10px;
    color: #ff5b24;
    color: var(--color-secondary);
  }

  .hotline-dashboard__departments {
    margin-bottom: 10px;
  }

  .hotline-dashboard__instructions {
    display: flex;
    justify-content: space-between;
    width: 285px;
    margin: auto;
  }

  .hotline-dashboard__instruction {
    overflow: hidden;
    width: 87px;
    min-height: 70px;
    border-radius: 5px;
    background-color: #f4f5f7;
    text-align: center;
  }

  .hotline-dashboard__instruction-step {
    padding: 3px;
    background-color: #e9eaef;
    color: #7a829d;
    text-transform: uppercase;
  }

  .hotline-dashboard__instruction-content {
    padding: 8px 3px;
  }

  .hotline-dashboard__instruction-text {
    word-break: break-all;
  }

  .hotline-dashboard__additional-info {
    display: flex;
    margin-top: 15px;
  }

  .hotline-dashboard__description-text {
    padding-right: 4px;
  }

  .hotline-dashboard__print-btn {
    margin-left: auto;
    padding-right: 25px;
    background-image: url(~@assets/imgs/undefined_imgs/printer-img.svg);
    background-position: 100% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .hotline-dashboard__print-btn:hover {
    text-decoration: underline;
  }

  .hotline-dashboard__department-info {
    margin-bottom: 15px;
  }
</style>
