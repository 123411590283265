(<template>
  <div class="news-list">
    <h3 class="news-title">{{ lastPublished.title }}</h3>
    <span class="news-content"
          v-html="shortedText"></span>
    <button v-if="lastPublished.content.length >= 100"
            class="news__link"
            @click="openNewsList">{{ $gettext('read more') }}</button>
  </div>
</template>)

<script>

  export default {
    props: {
      lastPublished: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      shortedText() {
        let classText = this.lastPublished.content;
        const hrefPos = this.lastPublished.content.search('href="');
        if (hrefPos > 0) {
          classText = this.addString(classText, hrefPos, 'class="sk-link" target="_blank" ');
        }

        if (this.lastPublished.content.length >= 100) {
          return classText.slice(0, 100) + '...';
        }
        return classText;
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openNewsList() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'news-list-modal'
        });
      },
      addString(str, index, stringToAdd) {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
      }
    }
  };
</script>

<style scoped>
.news-list {
  display: block;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
  list-style-type: none;
}

.news__link {
  font-weight: bold;
  text-decoration: underline;
}
</style>
