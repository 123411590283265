(<template>
  <div class="enterprise-btns">
    <div class="enterprise-btns__container">
      <div class="enterprise-btns__wrapper">
        <jobs-scope-select class="enterprise-btns__select" />
      </div>
      <div class="enterprise-btns__wrapper">
        <div v-if="!isEmailVerificationRequired"
             class="enterprise-btns__btns-wrapper">
          <button v-if="canCreateEmployee"
                  :class="{'in-progress': employeeProgressActive}"
                  class="sk-btn sk-btn--white enterprise-btns__btn is-progress-bar"
                  @click="addEmployee">{{ $gettext('Add employee') }}</button>
          <router-link :to="$makeRoute({name: 'BuyerEmployees'})"
                       class="sk-btn sk-btn--white enterprise-btns__btn">{{ $gettext('Show all my colleagues') }}</router-link>
          <router-link :to="$makeRoute({name: 'BuyerDepartments'})"
                       class="sk-btn sk-btn--white enterprise-btns__btn">{{ $gettext('Show all departments') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>)

<script>
  import JobsScopeSelect from '@/components/shared_components/JobsScopeSelect';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      'jobs-scope-select': JobsScopeSelect
    },
    data() {
      return {
        employeeProgressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsAdminOfEnterprise',
        'userIsFinanceManager',
        'isEmailVerificationRequired'
      ]),
      canCreateEmployee() {
        return this.userIsAdminOfEnterprise || this.userIsFinanceManager;
      },
    },
    methods: {
      addEmployee() {
        const departments = this.$store.state.EnterpriseStore.departments;
        if (!departments || !departments.length) {
          this.employeeProgressActive = true;
          this.$store.dispatch('EnterpriseStore/getDepartments').then(() => {
            this.employeeProgressActive = false;
            this.openAddEmployeeModal();
          });
        } else {
          this.openAddEmployeeModal();
        }
      },
      openAddEmployeeModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this
          }
        });
      }
    }
  };
</script>

<style>
  .enterprise-btns__select .sk-select {
    background-color: #fff;
  }
</style>

<style scoped>
  .enterprise-btns {
    width: 100%;
  }

  .enterprise-btns__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% + 20px);
    margin: -10px;
  }

  .enterprise-btns__select {
    min-width: 300px;
    margin-bottom: 0;
  }

  .enterprise-btns__wrapper {
    margin: 10px;
  }

  .enterprise-btns__btns-wrapper {
    display: flex;
    width: calc(100% + 10px);
    margin: 0 -5px;
  }

  .enterprise-btns__btn {
    width: auto;
    margin: 0 5px;
    padding: 0 8px;
  }

  @media (max-width: 767px) {
    .enterprise-btns__container {
      flex-direction: column-reverse;
    }

    .enterprise-btns__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 8px;
      line-height: normal;
      white-space: normal;
    }
  }
</style>
