(<template>
  <div class="assignment-search">
    <sk-input :placeholder="searchText"
              :preselected-value="searchQuery"
              :input-icon="'search'"
              :reg-exp="/[^0-9]/g"
              :mob-numeric-keyboard="true"
              class="assignment-search__search-field"
              @fieldvaluechanged="setSearchQuery" />
    <slot></slot>
    <button v-if="searchQuery"
            class="sk-btn sk-btn--white assignment-search__btn"
            @click="resetFilter">{{ $gettext('Reset filter') }}</button>
  </div>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import AssignmentSearchPrototype from '@/components/shared_components/form_fields/AssignmentSearchPrototype';

  export default {
    extends: AssignmentSearchPrototype,
    computed: {
      ...mapState('DashboardSearchStore', {
        searchQuery: ({searchQuery}) => searchQuery
      })
    },
    methods: {
      ...mapActions({
        getAssignments: 'DashboardSearchStore/getSearchAssignments',
        cancelSearch: 'DashboardSearchStore/cancelSearchAssignments'
      }),
      ...mapMutations({
        setProgress: 'DashboardSearchStore/setSearchAssignmentProgress',
        setSearchQueryMutation: 'DashboardSearchStore/setSearchQuery',
        setAssignments: 'DashboardSearchStore/setSearchAssignments'
      }),
      search(searchQuery) {
        this.requestWasMade = true;
        this.setProgress(true);
        const params = {items: 10, page: 1, searchQuery: searchQuery};
        this.getAssignments(params).then(() => {
          this.requestWasMade = false;
        }).catch(() => {
          this.requestWasMade = false;
        });
      },
      resetFilter() {
        this.setSearchQueryMutation('');
        this.setAssignments([]);
      }
    }
  };
</script>

<style scoped>
  .assignment-search {
    display: flex;
    flex-direction: column;
  }

  .assignment-search__search-field {
    margin: 0 auto 20px;
  }

  .assignment-search__btn {
    padding: 0 15px;
  }
</style>
