(<template>
  <div class="did-you-know__section">
    <div v-if="itemList"
         class="did-you-know__list">
      <div v-for="(item, index) in itemList.articles"
           :key="index"
           class="did-you-know__item">
        <span class="did-you-know__content">{{ item.name }}</span>
        <a :href="item.publicUrl"
           target="_blank"
           class="did-you-know__link">{{ $gettext('read more') }}</a>
      </div>
    </div>
    <div v-else
         class="did-you-know__empty-text-cont">
      <p class="did-you-know__empty-text">{{ $gettext('Place for did you know list') }}</p>
    </div>
  </div>
</template>)

<script>

  export default {
    props: {
      itemList: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>

<style scoped>
  .did-you-know__item {
    display: flex;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #e9eaef;
  }

  .did-you-know__content {
    flex: 0 1 80%;
  }

  .did-you-know__link {
    font-weight: bold;
  }

  .did-you-know__empty-text-cont {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #7a829d;
    font-size: 18px;
    text-align: center;
  }
</style>
