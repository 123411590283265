(<template>
  <div class="assignment-btns">
    <div v-for="(link, index) in links"
         :key="index"
         class="assignment-btns__item assignment-button"
         @click="handleLinkClick(link)">
      <div class="assignment-button__image"
           :class="link.iconClass"></div>
      <span class="assignment-button__text">{{ link.text }}</span>
      <div class="assignment-button__arrow-icon"></div>
    </div>
  </div>
</template>)

<script>
  import constants from '@/modules/constants';

  export default {
    data() {
      return {
        employeeProgressActive: false
      };
    },
    computed: {
      postTranslationLink() { return this.$isGodmode() ? `${constants.CONNECT_PATH?.replace(/\/+$/, '')}/create-translation-order?customeruid=${this.$store.getters['UserInfoStore/userUid']}` : this.$makeRoute({name: 'BuyerPostTranslationProject'}); },
      links() {
        return [
          {
            text: this.$gettext('Book an interpreter'),
            type: 'router-link',
            iconClass: 'book-interpreter-icon',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            text: this.$gettext('Order translation'),
            type: 'router-link',
            iconClass: 'order-translation-icon',
            action: this.postTranslationLink
          },
          {
            text: this.$gettext('Book sign language translator'),
            type: 'router-link',
            iconClass: 'book-sign-lang-icon',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 1}})
          },
          {
            text: this.$gettext('Other services'),
            type: 'router-link',
            iconClass: 'other-service-icon',
            action: this.$makeRoute({name: 'BuyerOtherServiceAssignment', params: {service: 'other'}})
          }
        ];
      }
    },
    methods: {
      handleLinkClick(link) {
        const action = typeof link.action === 'function' ? link.action() : link.action;
        typeof action === 'string' ? window.open(action, '_blank') : this.$router.push(action);
      },
    }
  };
</script>

<style scoped>
  .assignment-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .assignment-btns__item {
    display: flex;
    align-items: center;
    width: 24%;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
    cursor: pointer;
  }

  .salita-facelifted .assignment-btns__item:hover {
    box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0), 0 1px 2px 0 rgba(255, 255, 255, 0);
    transition: ease-in-out 0.125s;
  }

  .assignment-btns__item:hover {
    background-color: var(--assignment-quick-booking-hover-color);
    transition: ease-in-out 0.125s;
  }

  .assignment-btns__item:hover .assignment-button__arrow-icon {
    margin-right: 10px;
  }

  .assignment-button__image {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--assignment-quick-booking-icon-bg-color);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .book-interpreter-icon {
    background-image: var(--quick-book-interpreter-icon);
  }

  .order-translation-icon {
    background-image: var(--quick-book-translator-icon);
  }

  .book-sign-lang-icon {
    background-image: var(--quick-book-sign-lang-icon);
  }

  .other-service-icon {
    background-image: var(--quick-book-other-services-icon);
  }

  .assignment-button__text {
    padding: 0 15px 0 15px;
    font-weight: 400;
    font-size: 14px;
  }

  .assignment-button__arrow-icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: auto;
    background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_to_right_grey.svg);
    background-repeat: no-repeat;
  }

  @media (max-width: 850px) {
    .assignment-btns__item {
      padding: 10px;
    }

    .assignment-button__text {
      padding: 0 10px 0 10px;
    }
  }

  @media (max-width: 767px) {
    .assignment-btns {
      flex-wrap: wrap;
      gap: 12px;
    }

    .assignment-btns__item {
      display: block;
      width: 47%;
    }

    .assignment-button__arrow-icon {
      display: none;
    }

    .assignment-button__image {
      width: 24px;
      height: 24px;
      margin-bottom: 12px;
      background-color: transparent;
    }

    .assignment-button__text {
      padding: 0;
      font-size: 14px;
    }
  }
</style>
