(<template>
  <div ref="interList"
       class="interpreters-list">
    <div v-for="inter in formattedInterList"
         :key="inter.uid"
         class="interpreters-list__tile"
         :class="{'is-compact': compact}">
      <interpreter :interpreter="inter" />
    </div>
  </div>
</template>)

<script>
  import Interpreter from '@/components/interpreters_components/InterForDashboardList';

  export default {
    components: {
      interpreter: Interpreter
    },
    props: {
      interpreters: {
        type: Array,
        default: () => []
      },
      renderAmount: {
        type: Number,
        default: 0
      },
      compact: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      interpretersList() { return this.interpreters || []; },
      formattedInterList() {
        return this.interpretersList.map((inter) => {
          return {
            firstName: inter.person.firstName,
            uid: inter.person.uid,
            avatar: inter.person.avatar,
            favourite: inter.relation === 'favourite',
            online: inter.online,
            showPoliceLogo: inter.showPoliceLogo,
            showTemporaryPoliceLogo: inter.showTemporaryPoliceLogo,
          };
        }).filter((item, index) => index < this.renderAmount);
      }
    }
  };
</script>

<style scoped>
  .interpreters-list {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
    list-style-type: none;
  }

  .interpreters-list__tile {
    width: 20%;
    margin: 10px 0;
  }

  .interpreters-list__tile.is-compact {
    width: 33.333333%;
  }

  .interpreters-list__tile:hover {
    opacity: 0.7;
  }

  @media (max-width: 1084px) {
    .interpreters-list__tile {
      width: 33.333333%;
    }
  }
</style>
